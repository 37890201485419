/* tailwind configurations */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* css files imports */
@import url("./layout.css");
@import url("./homepage.css");
@import url("./radioButton.css");
@import url("./forms.css");
@import url("./modal.css");
@import url("./loader.css");
@import url("./result.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

/* custom arrow for all select inputs */
select {
  display: block;
  background: url("../assets/icons/downArrow.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.9em top 50%, 0 0;
  background-size: 0.8em auto, 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.pdf-footer-linear-gradient {
  background-color: #e7f6fb; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #e7f6fb, #c1e8f5);
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 1000px !important;
}
