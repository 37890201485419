.result_page_projects_container {
  background: url("../assets/images/result/background-result.svg");
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: left bottom;
}

@media (max-width: 1023px) and (min-width: 320px) {
  .result_page_projects_container {
    background: url("../assets/images/result/background-result-responsive.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
}

.epc-graph-container p {
  font-size: 14px;
}
.epc-graph-container .box-title-container {
  padding: 0.5rem;
}

@media (max-width: 1300px) and (min-width: 494px) {
  .epc-graph-container p {
    font-size: 8px !important;
  }
}

@media (max-width: 493px) and (min-width: 421px) {
  .epc-graph-container p {
    font-size: 6px !important;
  }
  .step3-line {
    height: 92px !important;
  }
}
@media (max-width: 420px) and (min-width: 320px) {
  .epc-graph-container p {
    font-size: 6px !important;
  }
  .step3-line {
    height: 92px !important;
  }
}
@media (max-width: 420px) and (min-width: 360px) {
  .epc-graph-container .box-title-container {
    padding: 0.4rem;
  }
}
@media (max-width: 359px) and (min-width: 320px) {
  .epc-graph-container .box-title-container {
    padding: 0.3rem;
  }
}

/* top boxes width */
@media (max-width: 415px) and (min-width: 340px) {
  .epc-graph-container .box-width {
    width: 9% !important;
  }
}
@media (max-width: 339px) and (min-width: 320px) {
  .epc-graph-container .box-width {
    width: 9% !important;
  }
}
/* bottom box width */
@media (max-width: 484px) and (min-width: 320px) {
  .epc-graph-container .box-bottom-width1 {
    /* width: 27% !important; */
    margin-top: 130px !important;
  }
  .epc-graph-container .box-bottom-width2 {
    /* width: 27% !important; */
    margin-top: 137px !important;
  }
}
@media (max-width: 767px) and (min-width: 480px) {
  .epc-graph-container .box-bottom-width1 {
    margin-top: 140px !important;
  }
  .epc-graph-container .box-bottom-width2 {
    margin-top: 148px !important;
  }
}

@media (max-width: 1265px) and (min-width: 767px) {
  .epc-graph-container .box-bottom-width1 {
    margin-top: 150px !important;
  }
  .epc-graph-container .box-bottom-width2 {
    margin-top: 155px !important;
  }
}
@media (max-width: 1279px) and (min-width: 1268px) {
  .epc-graph-container .box-bottom-width1 {
    margin-top: 155px !important;
  }
  .epc-graph-container .box-bottom-width2 {
    margin-top: 160px !important;
  }
}
@media (max-width: 1550px) and (min-width: 1280px) {
  .epc-graph-container .box-bottom-width1 {
    margin-top: 165px !important;
  }
  .epc-graph-container .box-bottom-width2 {
    margin-top: 170px !important;
  }
}
/* ---------------------------- */
/* step2  */
@media (max-width: 1023px) and (min-width: 320px) {
  .step2-line {
    height: 52px !important;
  }
}
@media (max-width: 1300px) and (min-width: 1024px) {
  .step3-line {
    height: 107px !important;
  }
}
@media (max-width: 510px) and (min-width: 490px) {
  .box-bottom-width2 .bottom-box-2 {
    height: 60px !important;
  }
}
@media (max-width: 405px) and (min-width: 320px) {
  .box-bottom-width2 .bottom-box-2 {
    height: 55px !important;
  }
}
