.homepage-banner {
  background-image: url("../assets/images/homepage/hero_homepage.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.large-screen-img {
  display: none;
}
.medium-screen-img {
  display: block;
}

@media (max-width: 1023px) and (min-width: 320px) {
  .medium-screen-img {
    display: none;
  }
}
/* for large screen desktop  */
@media (max-width: 2000px) and (min-width: 1900px) {
  .homepage-banner,
  .main-evaluation-container {
    height: 100vh;
  }
  .large-screen-content-and-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .home-evaluation-container {
    height: 100%;
  }
  .medium-screen-img {
    display: none !important;
  }
  .large-screen-img {
    display: block !important;
  }
}
@media (max-width: 2400px) and (min-width: 2001px) {
  .homepage-banner,
  .main-evaluation-container {
    height: 100vh;
  }
  .large-screen-content-and-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .home-evaluation-container {
    height: 100%;
  }
  .medium-screen-img {
    display: none !important;
  }
  .large-screen-img {
    display: block !important;
  }
}
@media (max-width: 2850px) and (min-width: 2401px) {
  .homepage-banner,
  .main-evaluation-container {
    height: 100vh;
  }
  .large-screen-content-and-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .home-evaluation-container {
    height: 100%;
  }
  .medium-screen-img {
    display: none !important;
  }
  .large-screen-img {
    display: block !important;
  }
}
@media (max-width: 4000px) and (min-width: 2851px) {
  .homepage-banner,
  .main-evaluation-container {
    height: 100vh;
  }
  .home-evaluation-container {
    height: 100%;
  }
  .large-screen-content-and-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .medium-screen-img {
    display: none !important;
  }
  .large-screen-img {
    display: block !important;
  }
}
/*  */
.homepage-banner .search-image {
  background-image: url("../assets/images/homepage/loupe.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 870px;
  width: 950px;
  padding: 80px 100px 80px 100px;
  margin-top: -36px;
}

/* section blue bacground for text only */
@media (max-width: 1023px) and (min-width: 320px) {
  .homepage-banner {
    background-image: url("../assets/images/homepage/illustration-homepage-22.svg");
    padding-top: 20%;
  }
}
/* scanner image css for mobile screen from 470 - 1023*/
@media (max-width: 1023px) and (min-width: 470px) {
  .homepage-banner .search-image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 460px;
    width: 470px;
    padding: 50px 82px 0px 82px;
    margin-top: initial;
  }
}
/* scanner image css for mobile screen from 320 - 469*/
@media (max-width: 469px) and (min-width: 320px) {
  .homepage-banner .responsvie-info {
    width: 85%;
  }
  .homepage-banner .search-image .title {
    font-size: 14px;
  }
  .homepage-banner .search-image .description p {
    font-size: 10px !important;
  }
  .homepage-banner .search-image {
    background-repeat: no-repeat;
    height: 380px;
    width: 335px;
    padding: 16px 30px 0px 46px;

    margin-top: initial;
  }
}

/* homepage-banner background image  */
@media (max-width: 1700px) and (min-width: 1500px) {
  .homepage-banner {
    height: 1000px;
  }
}
@media (max-width: 1800px) and (min-width: 1701px) {
  .homepage-banner {
    height: 1100px;
  }
}
@media (max-width: 2000px) and (min-width: 1801px) {
  .homepage-banner {
    height: 1200px;
  }
}
@media (max-width: 2300px) and (min-width: 2001px) {
  .homepage-banner {
    height: 1350px;
  }
}
@media (max-width: 2700px) and (min-width: 2301px) {
  .homepage-banner {
    height: 1600px;
  }
}
@media (max-width: 3000px) and (min-width: 2701px) {
  .homepage-banner {
    height: 1700px;
  }
}

/* section 2 */
.home-evaluation-container {
  background-color: #d6f0f7; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, #d6f0f7, #bae6f5);
}
/* @media (max-width: 1023px) and (min-width: 320px) {
  .home-evaluation-container {
    background-image: url("../assets/images/homepage/illustration-homepage-responsive-2.svg")!important;
    background-size: cover;
    background-color: none;
  }
} */
@media (max-width: 530px) and (min-width: 320px) {
  .home-evaluationResponsive-image img {
    height: 82px !important;
  }
}
@media (max-width: 610px) and (min-width: 470px) {
  .home-evaluationResponsive-image img {
    height: 100px !important;
  }
}
@media (max-width: 991px) and (min-width: 611px) {
  .home-evaluationResponsive-image img {
    height: 130px !important;
  }
}
@media (max-width: 1023px) and (min-width: 992px) {
  .home-evaluationResponsive-image img {
    height: 140px !important;
  }
}
.home-evaluation-container .downward-arrow-icon {
  height: 24px;
  background: #fff;
  width: 30px;
  position: absolute;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
  position: absolute;
  margin: 0px 0px 0px 150px;
}
/* 3rd section */
.improve-home-container .zoom {
  padding: 50px;
  transition: transform 0.2s;
  width: 200px;
  height: 200px;
}

@media (min-width: 1024px) {
  .improve-home-container .zoom:hover {
    -ms-transform: scale(1.4); /* IE 9 */
    -webkit-transform: scale(1.4); /* Safari 3-8 */
    transform: scale(1.4);
  }
}
.improve-home-container .zoom .img-container img {
  height: 6rem;
  transition: transform 0.3s;
}

@media (min-width: 1024px) {
  .improve-home-container .zoom:hover .img-container img {
    height: 4rem;
    transition: transform 0.3s;
  }
}
.improve-home-container .hvrbox-content-container {
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 200px;
  position: absolute;
  left: 0;
  top: 35px;
  padding: 0px 18px;
}

.improve-home-container .information-content-container {
  background: url("../assets/images/homepage/illustration-house-transparency.svg");
  background-repeat: no-repeat;
  background-size: 520px;
  background-position: right 25px bottom;
}
.improve-home-container .zoom .hover-show-img {
  display: none;
}

@media (min-width: 1024px) {
  .improve-home-container .zoom:hover .hvrbox-content-container {
    opacity: 1;
  }
  .improve-home-container .zoom:hover .hover-hide-img {
    display: none;
  }
  .improve-home-container .zoom:hover .hover-show-img {
    display: block;
  }
}
/* EPC image responsiveness */
@media (max-width: 1023px) and (min-width: 991px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 310px !important;
  }
}
@media (max-width: 990px) and (min-width: 840px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 340px !important;
  }
}
@media (max-width: 839px) and (min-width: 775px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 330px !important;
  }
}
@media (max-width: 774px) and (min-width: 700px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 380px !important;
  }
}
@media (max-width: 699px) and (min-width: 620px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 410px !important;
  }
}
@media (max-width: 619px) and (min-width: 570px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 460px !important;
  }
}
@media (max-width: 569px) and (min-width: 520px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 510px !important;
  }
}
@media (max-width: 519px) and (min-width: 466px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 580px !important;
  }
}
@media (max-width: 465px) and (min-width: 420px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 620px !important;
  }
}
@media (max-width: 419px) and (min-width: 380px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 750px !important;
  }
}
@media (max-width: 379px) and (min-width: 368px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 830px !important;
  }
}
@media (max-width: 367px) and (min-width: 320px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 880px !important;
  }
}
@media (max-width: 349px) and (min-width: 320px) {
  .improve-home-container .responsiveScreen-img-height img {
    height: 960px !important;
  }
}
