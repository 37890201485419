/* The radio-container */
.radio-container {
  display: flex;
  position: relative;
  /* padding-left: 50px; */
  padding: 0px 0px 0px 50px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-container .checkmark {
  position: absolute;
  top: 5;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff !important;
  border-radius: 50%;
  border: 3px solid #eaf7fc;
}
.radio-container .checkbox-input {
  border-radius: 25% !important;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #01a2da;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.radio-container .checkbox-input:after  {
  border-radius: 25% !important;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 16.7px;
  height: 17px;
  border-radius: 50%;
  background: #01a2da;
}
