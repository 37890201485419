@media (min-width: 1024px) {
  .forms-container .form-header {
    background: url("../assets/images/forms/banner-blue.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
}
/* on hover show white images and hide grey images in step1 */
.forms-container .step1-container .steps-box-hover .show-on-hover {
  display: none;
}
.forms-container .step1-container .steps-box-hover:hover .show-on-hover {
  display: block;
}
.forms-container .step1-container .steps-box-hover:hover .hide-on-hover {
  display: none;
}

/* forms page animation */
/*left  */
.animate-left {
  position: relative;
  -webkit-animation: slideIn 0.7s forwards;
  -moz-animation: slideIn 0.7s forwards;
  animation: slideIn 0.7s forwards;
}
@-webkit-keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 2 */
.animate-left2 {
  position: relative;
  -webkit-animation: slideIn2 0.7s forwards;
  -moz-animation: slideIn2 0.7s forwards;
  animation: slideIn2 0.7s forwards;
}
@-webkit-keyframes slideIn2 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn2 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn2 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 3 */
.animate-left3 {
  position: relative;
  -webkit-animation: slideIn3 0.7s forwards;
  -moz-animation: slideIn3 0.7s forwards;
  animation: slideIn3 0.7s forwards;
}
@-webkit-keyframes slideIn3 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn3 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn3 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 4 */
.animate-left4 {
  position: relative;
  -webkit-animation: slideIn4 0.7s forwards;
  -moz-animation: slideIn4 0.7s forwards;
  animation: slideIn4 0.7s forwards;
}
@-webkit-keyframes slideIn4 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn4 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn4 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 5 */
.animate-left5 {
  position: relative;
  -webkit-animation: slideIn5 0.7s forwards;
  -moz-animation: slideIn5 0.7s forwards;
  animation: slideIn5 0.7s forwards;
}
@-webkit-keyframes slideIn5 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn5 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn5 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 6 */
.animate-left6 {
  position: relative;
  -webkit-animation: slideIn6 0.7s forwards;
  -moz-animation: slideIn6 0.7s forwards;
  animation: slideIn6 0.7s forwards;
}
@-webkit-keyframes slideIn6 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn6 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn6 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 7 */
.animate-left7 {
  position: relative;
  -webkit-animation: slideIn7 0.7s forwards;
  -moz-animation: slideIn7 0.7s forwards;
  animation: slideIn7 0.7s forwards;
}
@-webkit-keyframes slideIn7 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn7 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn7 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* right */
/*left  */
.animate-right {
  position: relative;
  -webkit-animation: slideInRight 0.7s forwards;
  -moz-animation: slideInRight 0.7s forwards;
  animation: slideInRight 0.7s forwards;
}
@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 2 */
.animate-right2 {
  position: relative;
  -webkit-animation: slideInRight2 0.7s forwards;
  -moz-animation: slideInRight2 0.7s forwards;
  animation: slideInRight2 0.7s forwards;
}
@-webkit-keyframes slideInRight2 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight2 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight2 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 3 */
.animate-right3 {
  position: relative;
  -webkit-animation: slideInRight3 0.7s forwards;
  -moz-animation: slideInRight3 0.7s forwards;
  animation: slideInRight3 0.7s forwards;
}
@-webkit-keyframes slideInRight3 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight3 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight3 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 4 */
.animate-right4 {
  position: relative;
  -webkit-animation: slideInRight4 0.7s forwards;
  -moz-animation: slideInRight4 0.7s forwards;
  animation: slideInRight4 0.7s forwards;
}
@-webkit-keyframes slideInRight4 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight4 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight4 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 5 */
.animate-right5 {
  position: relative;
  -webkit-animation: slideInRight5 0.7s forwards;
  -moz-animation: slideInRight5 0.7s forwards;
  animation: slideInRight5 0.7s forwards;
}
@-webkit-keyframes slideInRight5 {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight5 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight5 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}


/* left 6 */
.animate-right6 {
  position: relative;
  -webkit-animation: slideInRight6 0.7s forwards;
  -moz-animation: slideInRight6 0.7s forwards;
  animation: slideInRight6 0.7s forwards;
}
@-webkit-keyframes slideInRight6 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight6 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight6 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}

/* left 7 */
.animate-right7 {
  position: relative;
  -webkit-animation: slideInRight7 0.7s forwards;
  -moz-animation: slideInRight7 0.7s forwards;
  animation: slideInRight7 0.7s forwards;
}
@-webkit-keyframes slideInRight7 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideInRight7 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight7 {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
