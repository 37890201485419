/* header css */
.header-container .language-selector {
  transition: 0.5s;
}
/* footer css */
@media (max-width: 1279px) and (min-width: 1024px) {
  /* .desktop-footerContent-container .specificScreen-width {
    justify-content: start !important;
  } */
  .desktop-footerContent-container .specificScreen-width {
    width: fit-content !important;
  }
  .desktop-footerContent-container {
    max-width: 1155px !important;
  }
}
